<template>
  <v-app>
    <v-main class="pa-0" style="background-color: #FAFAFA;">
      <router-view></router-view>
    </v-main>

<!--     <v-footer
      app
      inset
      color="transparent"
      height="56"
      class="px-0 d-flex justify-center"
    >
      <span class="copyright">&copy; 2022 lalalend Limited. All rights reserved.</span>
    </v-footer> -->
  </v-app>
</template>

<script>
export default {}
</script>

<style scoped>
.copyright {
  font-size: .8rem;
}
</style>
