import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'

import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import api from './api'
import formatter from './formatter'
import TokenMixin from '@/mixins/TokenMixin'
import PageHeaderSlot from './components/PageHeaderSlot.vue'



Vue.config.productionTip = false

Vue.use(api)
Vue.use(formatter)
Vue.mixin(TokenMixin)

Vue.component('PageHeaderSlot', PageHeaderSlot)

const DataValid = function (data) {
  if (data !== undefined && data !== null && data !== '') {
    if (typeof data === 'object') {
      return Object.keys(data).length > 0
    } else if (Array.isArray(data)) {
      return data.length > 0
    }

    if (data.length > 0) {
      return data.trim().length > 0
    }
    return true;

  } else {
    return false
  }
}

const staffType = {
  'full_time': "全職",
  'part_time': "兼職"
}

const staffStatus = {
  present: '現職',
  resign: '離職'
}

const adminType = {
  master: 'Master',
  manager: 'Manager',
  accountant: 'Accountant',
  admin: 'Admin'
}

const absentType = {
  "annual_leave": "年假 (Annual Leave)",
  "sick_leave": "病假 (Sick Leave)",
  "no_pay_leave": "無薪假 (No Pay Leave)",
  "comp_leave": "補假",
  "marriage_leave": "婚假 (Marriage Leave)",
  "maternity_leave": "產假 (Maternity Leave)",
  "jury_service_leave": "陪審員假 (Maternity Leave)",
  "compassionate_leave": "恩恤假 (Compassionate Leave)"
}

const absentTime = {
  full_day: "全日",
  am: "上午",
  pm: "下午"
}

const approveStatus = {
  "pending": "申請中",
  "cancelled": "已取消",
  "approved": "已批准",
  "unapproved": "已拒絕",
}

const claimType = [
  "交通費報銷",
  "差旅費報銷",
  "餐飲費報銷",
  "辦公用品報銷",
  "會議費報銷",
  "客戶招待報銷",
  "其他費用報銷"
]

const checkInTypeList = {
  check_in: "上班打卡",
  check_out: "下班打卡"
}

const checkInMethodList = {
  gps: "GPS",
  image: "普通"
}

const salaryStatusList = {
  paid: "已出糧",
  unpaid: "未出糧",
  fail: "出糧失敗",
}


Vue.filter('formatStaffType', function (value) {
  if (!value) return ''

  const type = staffType[value];

  return DataValid(type) ? type : value;

})

Vue.filter('formatStaffStatus', function (value) {
  if (!value) return ''

  const status = staffStatus[value];

  return DataValid(status) ? status : value;

})

Vue.filter('formatAdminType', function (value) {
  if (!value) return ''

  const type = adminType[value];

  return DataValid(type) ? type : value;

})

Vue.filter('formatAbsentType', function (value) {
  if (!value) return ''

  const type = absentType[value];

  return DataValid(type) ? type : value;

})

Vue.filter('formatAbsentTime', function (value) {
  if (!value) return ''

  const time = absentTime[value];

  return DataValid(time) ? time : value;

})

Vue.filter('formatApproveStatus', function (value) {
  if (!value) return ''

  const status = approveStatus[value];

  return DataValid(status) ? status : value;

})

Vue.filter('formatCheckInType', function (value) {
  if (!value) return ''

  const type = checkInTypeList[value];

  return DataValid(type) ? type : value;

})

Vue.filter('formatCheckInMethod', function (value) {
  if (!value) return ''

  const method = checkInMethodList[value];

  return DataValid(method) ? method : value;

})

Vue.filter('formatSalaryStatus', function (value) {
  if (!value) return ''

  const status = salaryStatusList[value];

  return DataValid(status) ? status : value;

})



Vue.filter('formatLogAction', function (value) {
  if (!value) return ''

  let result = ''
  switch (value) {
    case 'add':
      result = '新增'
      break
    case 'update':
      result = '更改'
      break
    case 'delete':
      result = '刪除'
      break

  }

  return result
})


Vue.prototype.$mediaPath = 'https://api.lamaisoninteriordesign.com/hr/media/'


Vue.prototype.$staffTypeList = Object.keys(staffType).map(key => {
  return {
    text: staffType[key],
    value: key
  };
})

Vue.prototype.$staffStatusList = Object.keys(staffStatus).map(key => {
  return {
    text: staffStatus[key],
    value: key
  };
})

Vue.prototype.$adminTypeList = Object.keys(adminType).map(key => {
  return {
    text: adminType[key],
    value: key
  };
})

Vue.prototype.$absentTypeList = Object.keys(absentType).map(key => {
  return {
    text: absentType[key],
    value: key
  };
})

Vue.prototype.$absentTimeList = Object.keys(absentTime).map(key => {
  return {
    text: absentTime[key],
    value: key
  };
})

Vue.prototype.$approveStatusList = Object.keys(approveStatus).map(key => {
  return {
    text: approveStatus[key],
    value: key
  };
})

Vue.prototype.$claimTypeList = claimType.map(val => {
  return {
    text: val,
    value: val
  };
})

Vue.prototype.$checkInTypeList = Object.keys(checkInTypeList).map(key => {
  return {
    text: checkInTypeList[key],
    value: key
  };
})

Vue.prototype.$checkInMethodList = Object.keys(checkInMethodList).map(key => {
  return {
    text: checkInMethodList[key],
    value: key
  };
})

Vue.prototype.$salaryStatusList = Object.keys(salaryStatusList).map(key => {
  return {
    text: salaryStatusList[key],
    value: key
  };
})



/* Vue.prototype.$genderList = [{
    'item-text': '男',
    'item-value': 'M'
  },
  {
    'item-text': '女',
    'item-value': 'F'
  },
];

Vue.prototype.$statusList = [{
    'item-text': '啟用中',
    'item-value': 'active'
  },
  {
    'item-text': '已停用',
    'item-value': 'inactive'
  },
]; */


Vue.prototype.$func = new(function () {
  this.log = value => {
    const currentLocation = window.location.hostname
    if (currentLocation.includes('localhost') || currentLocation.includes(
        'innpressionhost')) {
      console.log(value)
    }
  }

  this.firstFileToBase64 = fileImage => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      if (fileReader && fileImage != null) {
        fileReader.readAsDataURL(fileImage)
        fileReader.onload = () => {
          resolve(fileReader.result)
        }
        fileReader.onerror = error => {
          reject(error)
        }
      } else {
        reject(new Error('No file found'))
      }
    })
  }

  this.formatUploadProgress = (value, total) => {
    return ((value / total) * 100).toFixed(2)
  }

  this.formatDate = d => {
    return `${d.getFullYear()}-${d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1}-${d.getDate() < 10 ? `0${d.getDate()}` : d.getDate()
      }`
  }

  this.getDateStr = date => {
    if (DataValid(date)) {
      return date.split('T')[0]
    }

    return ''
  }

  this.getTimeStr = date => {
    if (DataValid(date)) {
      return date.split('T')[1].replace('Z', '')
    }

    return ''
  }

  this.getDateTimeStr = date => {
    if (DataValid(date)) {
      return `${date.split('T')[0]} ${date.split('T')[1].replace('Z', '')}`
    }

    return ''
  }

  this.appendZero = value => {
    if (parseInt(value) < 10) {
      return `0${value}`
    }
    return `${value}`
  }

  this.paddingZero = (value, len = 0) => {
    // if len = 3 -> 001
    // if len = 5 -> 00001
    let result = value.toString()

    if (len <= result.length) {
      return result
    }

    let count = len - result.length
    for (let i = 0; i < count; i++) {
      result = '0' + result
    }

    return result
  }

  this.getEngShortMonth = value => {
    let result = ''
    switch (value) {
      case 1:
        result = 'Jan'
        break
      case 2:
        result = 'Feb'
        break
      case 3:
        result = 'Mar'
        break
      case 4:
        result = 'Apr'
        break
      case 5:
        result = 'May'
        break
      case 6:
        result = 'Jun'
        break
      case 7:
        result = 'Jul'
        break
      case 8:
        result = 'Aug'
        break
      case 9:
        result = 'Sep'
        break
      case 10:
        result = 'Oct'
        break
      case 11:
        result = 'Nov'
        break
      case 12:
        result = 'Dec'
        break
    }

    return result
  }

  this.getAcceptFileFormat = type => {
    let fileFormatStr = "";
    let fileFormatArr = [];

    if (type === 'image') {
      fileFormatStr = "image/jpeg,image/jpg,image/png"
      fileFormatArr = ['png', 'jpeg', 'jpg'];
    } else if (type === 'video') {
      fileFormatStr = "video/mp4,video/webm"
      fileFormatArr = ['mp4', 'webm'];
    } else if (type === 'imageWithPDF') {
      fileFormatStr = "image/jpeg,image/jpg,image/png,application/pdf"
      fileFormatArr = ['png', 'jpeg', 'jpg', 'pdf'];
    }

    if (DataValid(fileFormatStr) && DataValid(fileFormatArr)) {
      return {
        formatStr: fileFormatStr,
        formatArr: fileFormatArr
      }
    }

    return null;
  }

  this.getAllDateBetweenTwoDates = (s, e) => {
    const result = [];

    let start = s;
    let end = e
    if (start > end) {
      let temp = end
      end = start;
      start = temp
    }

    const startDate = new Date(start)
    const endDate = new Date(end)
    const current = new Date(startDate.getTime())

    while (current <= endDate) {
      result.push(this.formatDate(new Date(current)))
      current.setDate(current.getDate() + 1)
    }

    return result;
  }

  this.arrRemoveRepeatItem = (arr) => {
    return [...new Set(arr)];
  }

  this.getMediaInsideHTMLString = (str) => {
   /*  let arr = []

    const dom = new DOMParser().parseFromString(str, "text/html");

    const imgTags = dom.getElementsByTagName('img');

    for (let i = 0; i < imgTags.length; i++) {
      const fullPath = imgTags[i].getAttribute('src').trim();

      arr.push(fullPath.substring(fullPath.indexOf("/backend/hr/media/") + 7));
    }

    return arr; */
  }

  this.orderDateArrInput = (val) => {
    if (Array.isArray(val)) {

      if (val.length === 2) {
        if (new Date(val[0]).getTime() > new Date(val[1]).getTime()) {
          return [val[1], val[0]]
        }
      }

      return val
    }

    return []
  }


  this.orderMonthArrInput = (val) => {
    if (Array.isArray(val)) {

      if (val.length === 2) {
        if (val[0] > val[1]) {
          return [val[1], val[0]]
        }
      }

      return val
    }

    return []
  }

  this.calculateTotalDay = (startDate, endDate) => {
    // 2022-01-01 to 2022-01-02 -> total 2 days

    let totalDay = 0;

    let start = new Date(startDate);
    let end = new Date(endDate);

    if (startDate > endDate) {
      start = new Date(endDate);
      end = new Date(startDate);
    }

    do {
      totalDay++;

      if (start.getTime() >= end.getTime()) {
        break;
      }

      start.setDate(start.getDate() + 1);
    } while (true);

    return totalDay;
  }

  this.convert24To12HourFormat = (time) => {
    let result = time;

    let timeArr = time.split(':');

    if (timeArr.length > 0) {
      let hour = parseInt(timeArr[0], 10);

      let twelveHour = 0;
      if (hour - 12 >= 0) {
        twelveHour = hour === 12 ? hour : hour - 12;
        if (twelveHour < 10) {
          twelveHour = '0' + twelveHour;
        }
        result = `${twelveHour}:${timeArr[1]}PM`;
      } else {
        twelveHour = hour === 0 ? 12 : hour;
        if (twelveHour < 10) {
          twelveHour = '0' + twelveHour;
        }
        result = `${twelveHour}:${timeArr[1]}AM`;
      }
    }

    return result;
  }
})()

Vue.prototype.$format = new(function () {
  // @keypress="$format.number($event)"

  this.number = event => {
    // [0-9]
    if (event.charCode >= 48 && event.charCode <= 57) {
      return true
    }

    return event.preventDefault()
  }

  this.floating = event => {
    // [0-9][.][0-9]
    if ((event.charCode >= 48 && event.charCode <= 57) || event.charCode === 46) {
      return true
    }

    return event.preventDefault()
  }

  this.numberWithNegative = event => {
    // [-][0-9]
    if ((event.charCode >= 48 && event.charCode <= 57) || event.charCode === 45) {
      return true
    }

    return event.preventDefault()
  }

  this.floatingWithNegative = event => {
    // [-][0-9][.][0-9]
    if ((event.charCode >= 48 && event.charCode <= 57) || event.charCode === 46 || event
      .charCode === 45) {
      return true
    }

    return event.preventDefault()
  }

  this.alphaNumeric = event => {
    // [0-9A-Za-z]
    if (
      (event.charCode >= 48 && event.charCode <= 57) ||
      (event.charCode >= 65 && event.charCode <= 90) ||
      (event.charCode >= 97 && event.charCode <= 122)
    ) {
      return true
    }

    return event.preventDefault()
  }

  this.blogSlug = event => {
    // A-Za-z0-9_-
    if (
      (event.charCode >= 48 && event.charCode <= 57) ||
      (event.charCode >= 65 && event.charCode <= 90) ||
      (event.charCode >= 97 && event.charCode <= 122) ||
      event.charCode === 45 ||
      event.charCode === 95
    ) {
      return true
    }

    return event.preventDefault()
  }
})()

Vue.prototype.$validate = new(function () {
  this.regex = {
    email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    phone: /^[2-9][0-9]{7}$/,
    hkMobile: /^[3-9][0-9]{7}$/,
    number: /^[0-9]+$/i,
    floating: /^[0-9]+([.]?[0-9]{1,2})?$/i,
    negativeNumber: /^[-]?[0-9]+$/i,
    negativeFloating: /^[-]?[0-9]+([.]?[0-9]+)?$/i,
    // blogSlug: /^[a-zA-Z0-9-_]+$/i
    blogSlug: /^[\u4e00-\u9fff\u3400-\u4dff\uf900-\ufaffA-za-z0-9-_]+$/i,
    hkid4Letter: /^[A-Z]{1,2}[0-9]{4}$/,
    date: /^\d{4}-\d{2}-\d{2}$/, // YYYY-MM-DD
    regexIdNum: /^[a-zA-Z0-9]{8,9}$/i,
    hkPhone: /^(^[2|3|4|5|6|7|8|9])+([0-9]{7})$/,
    twPhone: /^(^[1-9])+([0-9]{8})$/,
    macauPhone: /^([0-9]{8})$/,
    chinaPhone: /^(^[1])+([0-9]{10})$/,
  }

  this.DataValid = data => {
    return DataValid(data)
  }

  this.checkDoubleFileExtension = (fileName) => {
    const extension = fileName.match(/\.(.+)$/)[1];

    if (extension.split(".").length !== 1) {
      return true;
    }

    return false;
  }

  this.validateFileInput = (file, requiredType, requiredExtension) => {
    // requiredType = img, video...
    // requiredExtension = png, jpg, mp4...

    const limitFileSize = 300

    let result = ''
    const fileType = file.type
    const format = fileType.split('/')[1]

    if (this.checkDoubleFileExtension(file.name) ||
      (!requiredExtension.includes('*') && !requiredExtension.includes(format))
    ) {
      result = `只可上傳 ${requiredExtension.join(', ')} 檔案`
    } else if (file.size / 1000 / 1000 > limitFileSize) {
      result = `檔案大小不可大於 ${limitFileSize}MB`
    }

    return result
  }

  this.validateHKIDOrPassport = (value, allowEmpty = false) => {
    if (allowEmpty) {
      if (!DataValid(value)) {
        return true;
      }
    }
    return this.regex.regexIdNum.test(value) || '身分證/護照號碼格式不正確';
  }

  this.required = value => {
    return DataValid(value)
  }

  this.emailValid = value => {
    return this.regex.email.test(value) || '電郵格式不正確'
  }

  this.mobileValid = (value, allowEmpty = false) => {
    if (allowEmpty) {
      if (!DataValid(value)) {
        return true;
      }
    }

    return this.regex.phone.test(value) || '電話格式不正確'
  }

  this.hkMobileValid = value => {
    return this.regex.hkMobile.test(value) || '電話格式不正確'
  }

  this.isNumber = (value, allowEmpty = false) => {
    // true = number only
    return this.regex.number.test(value) || '請輸入整數'
  }

  this.isNumberOrEmpty = value => {
    if (DataValid(value)) {
      return this.regex.number.test(value) || '請輸入整數'
    }

    return true
  }

  this.validatePhoneList = (phone, countryCode) => {
    if (DataValid(countryCode)) {
      if (DataValid(phone)) {
        if (countryCode === '852') {
          return this.regex.hkPhone.test(phone) || '電話格式不正確'
        } else if (countryCode === '886') {
          return this.regex.twPhone.test(phone) || '電話格式不正確'
        } else if (countryCode === '853') {
          return this.regex.macauPhone.test(phone) || '電話格式不正確'
        } else if (countryCode === '86') {
          return this.regex.chinaPhone.test(phone) || '電話格式不正確'
        }
      } else {
        return false
      }
    }

    return true
  }

  this.isFloating = value => {
    return this.regex.floating.test(value) || '請輸入整數或小數'
  }

  this.isNegativeNumber = value => {
    return this.regex.negativeNumber.test(value) ||
      'Please input a positive or negative integer'
  }

  this.isNegativeFloating = value => {
    return this.regex.negativeFloating.test(value) ||
      'Please input a positive or negative integer/decimal number'
  }

  this.blogSlugValid = value => {
    return this.regex.blogSlug.test(value) || '只可輸入中文字, A-Z, a-z, 0-9, 底線(_) 和連字號(-)'
  }

  this.hkid4LetterValid = value => {
    return this.regex.hkid4Letter.test(value) || '只可輸入英文字及頭4位數字'
  }

  this.isDateValid = value => {
    if (!DataValid(value)) {
      return false
    }

    if (!this.regex.date.test(value)) {
      return false
    }

    const date = new Date(value)

    const timestamp = date.getTime()

    if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
      return false
    }

    return date.toISOString().startsWith(value)
  }

  this.largerThanNumber = (value, compareNum = 0, isFloating = false) => {
    // input cannot smaller than xx

    if (DataValid(value)) {
      const input = isFloating ? parseFloat(value.toString()) : parseInt(value.toString());

      if (input <= compareNum) {
        return `數量不可少於${compareNum}`
      }

      return true;
    }
    return false;
  }

})()

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
