require('./overrides.scss')

export default {
  theme: {
    themes: {
      light: {
        primary: '#bc8838',
        accent: '#404040',
        secondary: '#0E3DC6',
        success: '#006404',
        info: '#16B1FF',
        warning: '#C98F0C',
        error: '#BD1D21',
      },
      dark: {
        primary: '#bc8838',
        accent: '#404040',
        secondary: '#0E3DC6',
        success: '#30c513',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#BD1D21',
      },
    },
  },
}
