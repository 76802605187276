<script>
import Cookies from 'js-cookie'

export default {
  name: 'TokenMixin',
  methods: {
    getCurrentUserData() {
      const data = Cookies.get('LA_MAISON_HR_CMS_LID')

      if (data !== undefined && data !== null && data !== '') {
        return JSON.parse(Cookies.get('LA_MAISON_HR_CMS_LID'))
      }

      return {}
    },
    getAdminToken() {
      const userData = this.getCurrentUserData()
      return userData.verify_token || ''
    },
    getAdminType() {
      const userData = this.getCurrentUserData()
      return userData.admin_type || 'admin'
    },
    setUserData(data) {
      const userData = this.getCurrentUserData()
      if (this.$validate.DataValid(userData)) {
        Cookies.remove('LA_MAISON_HR_CMS_LID')
      }

      Cookies.set('LA_MAISON_HR_CMS_LID', JSON.stringify(data), {
        path: '/',
        expires: new Date(new Date().getTime() + 2 * 60 * 60 * 1000), // expires after 2 hour
        sameSite: 'strict',
        secure: true,
      })
    },
    async checkUserData() {
      let resultType = false
      const userData = this.getCurrentUserData()

      if (this.$validate.DataValid(userData)) {
        if (
          this.$validate.DataValid(userData.id) &&
          this.$validate.DataValid(userData.last_login) &&
          this.$validate.DataValid(userData.username) &&
          this.$validate.DataValid(userData.verify_token)
        ) {
          resultType = true
        }
      }

      if (!resultType) {
        this.forceLogout()
      }

      return resultType
    },
    async clearVerifyToken(id) {
      try {
        const result = await this.$XHR.api('cms_admin_logout', { id: parseInt(id, 10) })
        this.$func.log('-----remove verify_token success-----')
      } catch (error) {
        this.$func.log('-----remove verify_token fail-----')
      } finally {
        Cookies.remove('LA_MAISON_HR_CMS_LID')
      }
    },
    async forceLogout() {
      this.$store.dispatch('toggleLoadingPage', true)

      const userData = this.getCurrentUserData()
      if (this.$validate.DataValid(userData)) {
        await this.clearVerifyToken(userData.id)

        Cookies.remove('LA_MAISON_HR_CMS_LID')

        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: '登入過期，請重新登入',
          type: 'error',
          refresh: false,
          redirect: 'login',
        })
      } else {
        Cookies.remove('LA_MAISON_HR_CMS_LID')

        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: '登入過期，請重新登入',
          type: 'error',
          refresh: false,
          redirect: 'login',
        })
      }
    },
  },
}
</script>
